<template>
  <div class="content-wrapper">
    <page-header
        screen-name="Ver Usuários"
        :link-items="linkItems"
    />

    <div class="card">
      <section class="p-2">
        <b-form>
          <b-row class="mb-2">
            <b-col cols="12">
              <h3>Lista de Usuários Departamento Api</h3>
              <p>
                Para realizar uma busca, selecione o(s) filtros necessário(s) e clique no botão buscar:
              </p>
            </b-col>
          </b-row>

          <b-row class="align-items-center">
            <b-col
                sm="6"
                lg="4"
            >
              <b-form-group
                  label="Departamento"
                  label-for="department"
              >
                <v-select
                    id="department"
                    v-model="search.department"
                    :options="departments"
                    variant="custom"
                    item-text="descricao"
                    item-value="id_departamento"
                    placeholder="Escolha um departamento"
                    label="descricao"
                />
              </b-form-group>
            </b-col>

            <b-col
                sm="12"
                lg="4"
                class="actions-area"
            >
              <div class="action-search">
                <button
                    type="button"
                    class="btn custom-blue mr-2"
                    @click.prevent="findAll"
                >
                  <feather-icon
                      icon="SearchIcon"
                  />
                  Pesquisar
                </button>

                <button
                    type="button"
                    class="btn custom-outline-blue"
                    @click="clearFilters"
                >
                  <feather-icon
                      icon="XIcon"
                  />
                  Limpar
                </button>
              </div>
            </b-col>
          </b-row>

          <b-row class="mt-3 mb-1">
            <b-col>
              <b-link
                  type="button"
                  class="btn custom-blue"
                  :to="{ name: 'usuarios-integracao-novo' }"
              >
                <feather-icon
                    icon="PlusIcon"
                />
                Adicionar novo usuário
              </b-link>
            </b-col>
          </b-row>
        </b-form>
      </section>

      <section v-if="loadingTable">
        <div class="spinner-area">
          <b-spinner
              variant="custom"
              label="Loading..."
          />
        </div>
      </section>

      <section v-if="!loadingTable">
        <b-row>
          <b-col
              md="12"
              lg="6"
          >
            <h1 class="px-2">
              {{ titlePage }}
            </h1>
          </b-col>
        </b-row>

        <b-row class="my-2">
          <b-col
              class="px-3"
              sm="6"
          >
            <div
                v-if="showTable"
                class="d-flex justify-center-center align-items-center"
            >
              <span class="mr-50">Mostrar</span>
              <v-select
                  id="orders"
                  v-model="paginationData.defaultSize"
                  :options="tableItems.qtdRowsTable"
                  :clearable="false"
                  @input="updateQtdView($event)"
              >
                <span slot="no-options">Nenhuma opção selecionável.</span>
              </v-select>
            </div>
          </b-col>

          <b-col cols="12">
            <b-alert
                variant="primary"
                :show="tableItems.noItems"
                class="mx-2 mt-5"
            >
              <div class="alert-body d-flex justify-content-center">
                <span class="text-primary">
                  <strong
                      class="text-primary"
                  >Nenhum registro encontrado.</strong>
                </span>
              </div>
            </b-alert>

            <b-alert
                variant="primary"
                :show="tableItems.tableError"
                class="mx-2 mt-5"
            >
              <div class="alert-body d-flex justify-content-center">
                <span class="text-primary">
                  <strong
                      class="text-primary"
                  >Sistema de busca indisponível no momento.</strong>
                </span>
              </div>
            </b-alert>
          </b-col>

          <b-col
              v-if="showTable"
              cols="12"
              class="my-2"
          >
            <b-table
                id="listCompaniesTable"
                responsive
                sticky-header="380px"
                :busy.sync="tableItems.tabelaOcupada"
                :no-local-sorting="true"
                :fields="tableItems.fields"
                :items="tableItems.items"
                @context-changed="handleOrderTable"
            >
              <template #cell(id_departamento_api)="row">
                <span>{{ row.value }}</span>
              </template>

              <template #cell(usuario)="row">
                <span>{{ row.value }}</span>
              </template>

              <template #cell(descricao)="row">
                <span>{{ row.value }}</span>
              </template>

              <template #cell(uf)="row">
                <span>{{ row.value }}</span>
              </template>

              <template #cell(ativo)="row">
                <b-form-checkbox
                    v-model="row.value"
                    @change="defineStatusUser(row)"
                    class="custom-control-success"
                    name="check-button"
                    :disabled="switchDisabled"
                    switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                          <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </template>

              <template #cell(criacao)="row">
                <span>{{ moment(row.value).format("DD/MM/YYYY HH:mm") }}</span>
              </template>

              <template #cell(actions)="row">
                <button-icon
                    color="#2772C0"
                    size="18"
                    class-name="m-button-icon"
                    feather-icon="EditIcon"
                    @action="redirectUpdatePage(row.item)"
                />
                <button-icon
                    color="#2772C0"
                    size="18"
                    class-name="m-button-icon"
                    feather-icon="Trash2Icon"
                    @action="openModalDelete(row.item)"
                />
              </template>
            </b-table>
          </b-col>

          <b-col
              v-if="showTable"
              class="px-3"
              sm="12"
          >
            <CustomPagination
                :paginacao="paginationData"
                @page-cliked="updateCurrentPage"
            />
          </b-col>
        </b-row>
      </section>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BTable,
  BSpinner,
  BAlert,
  BLink,
  BFormCheckbox,
} from 'bootstrap-vue'
import PageHeader from '@/views/components/custom/page-header/PageHeader';
import moment from 'moment'
import vSelect from 'vue-select'
import ButtonIcon from '@/views/components/custom/Buttons/ButtonIcon/ButtonIcon';
import CustomPagination from '@/views/components/custom/pagination/CustomPagination';
import {deleteDepartmentApiUser, getAllDepartments, getAllUsersDepApi, handleDefineStatusUser} from './requests';
import {confirmAction, successMessage, warningMessage} from "@/libs/sweetalerts";

export default {
  title: "Usuários Integração",
  components: {
    PageHeader,
    vSelect,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BTable,
    BSpinner,
    BAlert,
    BLink,
    BFormCheckbox,
    ButtonIcon,
    CustomPagination,
  },

  data() {
    return {
      moment,
      titlePage: '',

      linkItems: [
        {
          name: 'Usuários Departamento Api',
          routeName: '',
        },
        {
          name: 'Ver Usuários',
          active: true,
        },
      ],

      search: {
        department: '',
      },

      loadingTable: false,
      showTable: false,

      paginationData: {
        currentPage: 1,
        totalLines: 0,
        fromLine: 0,
        toLine: 0,
        defaultSize: 10,
      },

      tableItems: {
        noItems: false,
        tableError: false,
        tableBusy: false,
        qtdRowsTable: [10, 25, 50, 100],
        tableOrder: 'asc',
        orderFiled: 'id_departamento_api',
        fields: [
          { key: 'id_departamento_api', label: 'ID' },
          { key: 'usuario', label: 'USUÁRIO' },
          { key: 'descricao', label: 'DESCRICAO' },
          { key: 'uf', label: 'UF' },
          { key: 'criacao', label: 'CRIADO EM' },
          { key: 'ativo', label: 'ATIVO' },
          {
            key: 'actions',
            label: 'AÇÕES',
            class: 'width-custom-column text-center',
          },
        ],
        items: [],
      },

      switchDisabled: false,

      departments: [],
    }
  },

  mounted() {
    this.findAllDepartments()
    this.findAll()
  },

  methods: {
    async findAll() {
      this.tableItems.tableError = false
      this.tableItems.noItems = false
      this.loadingTable = true

      await getAllUsersDepApi(this.setParams())
          .then(response => {
            if (response.status === 200) {
              if (response.data.data.length > 0) {
                this.showTable = true
                this.tableItems.items = response.data.data
                this.tableItems.tabelaOcupada = false
                this.handlePagination(response.data)
                return
              }

              this.tableItems.noItems = true
              this.showTable = false
            }
          })
          .catch(() => {
            this.tableItems.tableError = true
            this.showTable = false
          })

      this.loadingTable = false
    },

    async findAllDepartments() {
      await getAllDepartments()
        .then(response => {
          this.departments = response.data
        })
        .catch(() => {})
    },

    async defineStatusUser({ item })
    {
      this.switchDisabled = true

      await handleDefineStatusUser(item.id_departamento_api)
        .then(() => {})
        .catch(() => {})

      this.switchDisabled = false
    },

    redirectUpdatePage({ id_departamento_api }) {
      this.$store.commit('departamentoApiState/SET_CHOOSE_DEPARTMENT_API_USER', id_departamento_api)

      this.$router.replace({ name: 'usuarios-integracao-atualizar' })
    },

    openModalDelete({ id_departamento_api }) {
      confirmAction('Deseja excluir este registro ?')
          .then(() => {
            this.handleRemoveItem(id_departamento_api)
          })
    },

    async handleRemoveItem(id_departamento_api) {
      this.loadingTable = true

      await deleteDepartmentApiUser(id_departamento_api)
        .then(response => {
          if (response.status === 204) {
            successMessage('Registro excluído com sucesso.')
            this.findAll()
          }
        })
        .catch(error => {
          const errors = error.response.status === 400 || error.response.status === 404

          if (errors) {
            return warningMessage(error.response.data.error)
          }

          return warningMessage('Não foi possível realizar a sua solicitação.')
        })

      this.loadingTable = false
    },

    clearFilters() {
      this.search.department = ''
    },

    handleOrderTable(context) {
      this.tableItems.orderFiled = context.sortBy
      this.tableItems.tableOrder = context.sortDesc ? 'desc' : 'asc'

      this.findAll()
    },

    setParams() {
      return {
        colunanome: this.tableItems.orderFiled,
        colunaOrdem: this.tableItems.tableOrder,
        porPagina: this.paginationData.defaultSize,
        pagina: this.paginationData.currentPage,
        idDepartamento: this.search.department ? this.search.department.id_departamento : null,
      }
    },

    handlePagination(data) {
      this.paginationData.fromLine = data.from
      this.paginationData.toLine = data.to
      this.paginationData.totalLines = data.total
      this.paginationData.currentPage = data.current_page
    },

    updateQtdView(event) {
      if (!event) {
        this.paginationData.defaultSize = 10
      }

      this.paginationData.currentPage = 1
      this.findAll()
    },

    updateCurrentPage(page) {
      this.paginationData.currentPage = page
      this.findAll()
    },
  },
}
</script>

<style lang="scss" scoped>
.action-search {
  display: flex;
}

.m-button-icon {
  margin: .15rem;
}

@media (max-width: 400px) {
  .action-search {
    display: flex;
    flex-direction: column;

    button {
      width: 100%;
    }
  }

  .button-plus {
    width: 100%;
  }
}
</style>
